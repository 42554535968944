export const WORDS = [
  'seksu',
  'ratai',
  'taiką',
  'šešis',
  'merfi',
  'jokio',
  'vertė',
  'klube',
  'šonai',
  'nafta',
  'džoni',
  'sėdėk',
  'gulėk',
  'busiu',
  'takas',
  'lentą',
  'pasuk',
  'manėm',
  'biure',
  'rasta',
  'makai',
  'zakai',
  'stebi',
  'meile',
  'todai',
  'voras',
  'hanai',
  'laida',
  'manau',
  'rytus',
  'einam',
  'kaltu',
  'audra',
  'plaka',
  'nuomą',
  'imkis',
  'buvęs',
  'paštą',
  'parke',
  'duoda',
  'gimei',
  'biurą',
  'bosas',
  'kupai',
  'sendi',
  'petai',
  'darėm',
  'duokš',
  'mintį',
  'nuveš',
  'skris',
  'duodi',
  'gėrei',
  'puikų',
  'darbo',
  'tonis',
  'žirgą',
  'turtų',
  'niekų',
  'sūnui',
  'kūnas',
  'stalo',
  'tapęs',
  'zinau',
  'sinde',
  'krūvą',
  'kulką',
  'sakot',
  'miega',
  'kačių',
  'turim',
  'nesam',
  'jauna',
  'mylim',
  'neisi',
  'bukas',
  'lauką',
  'rūmai',
  'vykti',
  'išeit',
  'kieme',
  'gauni',
  'ričis',
  'kodus',
  'suras',
  'akies',
  'kurio',
  'šanso',
  'vaiku',
  'būrio',
  'bando',
  'matęs',
  'vagia',
  'žemių',
  'grojo',
  'tavęs',
  'čekių',
  'filme',
  'vardu',
  'duodu',
  'rytoj',
  'mirsi',
  'galia',
  'žydas',
  'tėvas',
  'varom',
  'tiesą',
  'dieną',
  'kapas',
  'kvepi',
  'antro',
  'stogą',
  'kasti',
  'šalta',
  'eismo',
  'sapne',
  'baigs',
  'pirmu',
  'reike',
  'kalbų',
  'šaunu',
  'liepk',
  'nedai',
  'bilis',
  'fiona',
  'pačia',
  'dangų',
  'pašok',
  'rusai',
  'pilvo',
  'šalin',
  'lorde',
  'menas',
  'kainą',
  'matai',
  'rūkai',
  'gyvai',
  'mišką',
  'darom',
  'dėdei',
  'sakėt',
  'pačiu',
  'šituo',
  'gimęs',
  'miler',
  'stotį',
  'laukt',
  'siūlė',
  'tarsi',
  'namie',
  'liktų',
  'krizė',
  'sūrio',
  'ašarų',
  'nudės',
  'narys',
  'ronas',
  'senis',
  'grand',
  'širdį',
  'kelia',
  'donai',
  'vieta',
  'bosui',
  'gėlių',
  'valgo',
  'vogti',
  'eliza',
  'dėžių',
  'visos',
  'vieną',
  'tauta',
  'rėjus',
  'grynu',
  'sarge',
  'lukai',
  'pačią',
  'kiemą',
  'lygio',
  'įdėjo',
  'pikta',
  'atšok',
  'tomui',
  'lūpos',
  'meilė',
  'audrą',
  'laris',
  'viską',
  'stato',
  'valia',
  'grupe',
  'užmuš',
  'vyrus',
  'paltą',
  'rašai',
  'maras',
  'lauko',
  'tėtuk',
  'šitas',
  'likai',
  'jokių',
  'likus',
  'deize',
  'klere',
  'ponas',
  'pirmo',
  'baigt',
  'pelną',
  'džeri',
  'jiedu',
  'uolos',
  'veikė',
  'kampe',
  'kaltę',
  'semai',
  'skuba',
  'pabūt',
  'ryšys',
  'žiedų',
  'trauk',
  'juose',
  'išeis',
  'sulig',
  'gėrio',
  'skola',
  'penio',
  'banga',
  'kurią',
  'trise',
  'matei',
  'karys',
  'dujas',
  'lordo',
  'mieli',
  'kilti',
  'seras',
  'našta',
  'bitės',
  'myliu',
  'išmes',
  'šokas',
  'dingę',
  'siųsk',
  'šonas',
  'batai',
  'proga',
  'drąsi',
  'įgula',
  'katia',
  'lūpas',
  'kalti',
  'jokia',
  'kažin',
  'sakęs',
  'melas',
  'džonu',
  'kitur',
  'inoma',
  'darei',
  'vištą',
  'palik',
  'dviem',
  'forma',
  'megan',
  'sūnum',
  'laura',
  'bėgte',
  'žmona',
  'kampu',
  'valią',
  'neesu',
  'savęs',
  'būsit',
  'miręs',
  'rūmus',
  'sėkmė',
  'saule',
  'mažus',
  'naują',
  'lipam',
  'ratus',
  'mesti',
  'kitus',
  'šokio',
  'tėvus',
  'bloko',
  'žirgų',
  'tėvui',
  'norit',
  'tavim',
  'džeki',
  'laidą',
  'skubu',
  'bazės',
  'galit',
  'dantį',
  'perka',
  'endis',
  'gersi',
  'wushu',
  'kelti',
  'liova',
  'kovai',
  'užeit',
  'džese',
  'šiuos',
  'kokių',
  'visad',
  'gasai',
  'emili',
  'pumba',
  'lauki',
  'penis',
  'turiu',
  'įėjau',
  'narve',
  'stovi',
  'uždek',
  'benas',
  'lokio',
  'turtą',
  'kailį',
  'šūdas',
  'tikri',
  'tomas',
  'kitko',
  'minia',
  'pigus',
  'pėdos',
  'lšeik',
  'vakar',
  'prizą',
  'manys',
  'tušti',
  'merfe',
  'bėgau',
  'nudėt',
  'bloga',
  'plaut',
  'miegu',
  'hario',
  'kieta',
  'klark',
  'ribos',
  'tiems',
  'šefas',
  'maiki',
  'mėnuo',
  'tomai',
  'keliu',
  'vartų',
  'užuot',
  'kaukė',
  'trijų',
  'likęs',
  'žmoną',
  'juoda',
  'atims',
  'sakai',
  'paims',
  'asile',
  'bobas',
  'magas',
  'žmogų',
  'skolą',
  'taksi',
  'šaudo',
  'šįryt',
  'pagal',
  'erdvę',
  'labos',
  'leisk',
  'daryk',
  'nebus',
  'visko',
  'kažką',
  'vilai',
  'tautą',
  'mūšis',
  'jeigu',
  'nitro',
  'rusas',
  'aukle',
  'daina',
  'mušti',
  'sūnūs',
  'margo',
  'dalis',
  'pytai',
  'pyktį',
  'gatvė',
  'krūva',
  'vadai',
  'pikti',
  'prašo',
  'davei',
  'selma',
  'jokiu',
  'judvi',
  'mūšio',
  'banką',
  'balti',
  'laukė',
  'neimk',
  'vaiko',
  'deizė',
  'uosto',
  'slypi',
  'torus',
  'ateit',
  'liusė',
  'geros',
  'kuzią',
  'dugną',
  'vyras',
  'tokio',
  'tikęs',
  'dievų',
  'rojus',
  'senio',
  'žinau',
  'einat',
  'skolų',
  'gyvas',
  'kogut',
  'ežero',
  'vyrai',
  'išvis',
  'statė',
  'girdi',
  'blogo',
  'būklė',
  'naktį',
  'diana',
  'viltį',
  'sėdim',
  'nariu',
  'varyk',
  'sieną',
  'langų',
  'svorį',
  'apsuk',
  'filmo',
  'gokas',
  'metrų',
  'rašau',
  'sapnų',
  'pirmą',
  'padėk',
  'kažko',
  'lifto',
  'sesuo',
  'fondo',
  'peilį',
  'amžių',
  'sašai',
  'bandė',
  'kiemo',
  'regis',
  'senuk',
  'perku',
  'taigi',
  'akmuo',
  'ledas',
  'pelno',
  'sakys',
  'likti',
  'tikrų',
  'gėlės',
  'spyna',
  'ramus',
  'kurgi',
  'bičas',
  'buvai',
  'eikit',
  'šalių',
  'eikim',
  'nudėk',
  'siūlo',
  'gerus',
  'rouze',
  'sėkla',
  'visgi',
  'dugno',
  'liksi',
  'mirti',
  'klano',
  'vietų',
  'darbe',
  'rimto',
  'tikra',
  'žūsta',
  'žinok',
  'truko',
  'sveta',
  'radom',
  'delta',
  'planą',
  'kaulo',
  'paimk',
  'gavom',
  'kelai',
  'gauja',
  'netgi',
  'anglų',
  'balta',
  'maldą',
  'dirbo',
  'sirgo',
  'grotų',
  'mamos',
  'madam',
  'manes',
  'šioje',
  'žalių',
  'belle',
  'spec.',
  'matėm',
  'visas',
  'keist',
  'tilto',
  'centų',
  'greta',
  'užsuk',
  'pieną',
  'fakto',
  'deivė',
  'pasas',
  'likit',
  'tampa',
  'poryt',
  'šakės',
  'tigre',
  'drąsa',
  'žemyn',
  'treji',
  'saugu',
  'veide',
  'naudą',
  'kalbu',
  'bėgam',
  'dantų',
  'skani',
  'rašyk',
  'gerai',
  'kovok',
  'užima',
  'geriu',
  'smagu',
  'dieve',
  'smogė',
  'lioša',
  'antra',
  'vikai',
  'fedia',
  'nauju',
  'labai',
  'rendi',
  'merga',
  'sceną',
  'balto',
  'damos',
  'tipai',
  'jinai',
  'bombą',
  'cirką',
  'kardo',
  'bates',
  'dažai',
  'įsakė',
  'vėžio',
  'kaiju',
  'ilgas',
  'jėzau',
  'lazdą',
  'bėgių',
  'sudie',
  'salos',
  'gatve',
  'leidi',
  'lempą',
  'domai',
  'buvot',
  'kartą',
  'gimdo',
  'tanią',
  'žinos',
  'dirba',
  'blogą',
  'teisi',
  'dėjau',
  'dženė',
  'postą',
  'žemai',
  'bobos',
  'pytas',
  'kartu',
  'šiais',
  'šikną',
  'trūko',
  'pirma',
  'algos',
  'rūšių',
  'taurę',
  'spėti',
  'bazas',
  'kauke',
  'rasti',
  'minus',
  'ausys',
  'garis',
  'dirbk',
  'tėčio',
  'mudvi',
  'burną',
  'judėk',
  'sosto',
  'nauji',
  'dienu',
  'tapau',
  'kieti',
  'kaklo',
  'bauda',
  'dagas',
  'puiku',
  'dženi',
  'italų',
  'turit',
  'pries',
  'užtat',
  'įdomi',
  'liusi',
  'imkit',
  'žalos',
  'manyk',
  'kario',
  'tvorą',
  'žvėrį',
  'turėk',
  'sutik',
  'banko',
  'salės',
  'puodą',
  'ponui',
  'pikto',
  'mėšlo',
  'robis',
  'rūkyt',
  'padek',
  'radai',
  'santa',
  'kaiką',
  'sunku',
  'pečių',
  'ratas',
  'atėjo',
  'gavot',
  'pirko',
  'tokiu',
  'lizdą',
  'kalės',
  'storą',
  'rėjau',
  'radau',
  'kurių',
  'telpa',
  'kėjau',
  'žemės',
  'donas',
  'kokie',
  'duosi',
  'sergi',
  'kovos',
  'rimtų',
  'kaktą',
  'mires',
  'šeima',
  'dički',
  'todėi',
  'darai',
  'nueik',
  'paštu',
  'tokių',
  'čarli',
  'verkė',
  'tikiu',
  'elena',
  'klase',
  'vitia',
  'tarti',
  'grįši',
  'ranka',
  'šokis',
  'motin',
  'klasę',
  'vagis',
  'šaudė',
  'nieką',
  'ripli',
  'kardą',
  'džesi',
  'skydą',
  'galią',
  'vaike',
  'gėles',
  'katės',
  'lobis',
  'mazgų',
  'pabus',
  'testą',
  'lovoj',
  'erdvė',
  'romos',
  'naujo',
  'lanko',
  'turės',
  'mados',
  'užims',
  'nikai',
  'vynas',
  'faras',
  'stoti',
  'rašyt',
  'ataka',
  'redai',
  'žodis',
  'tikėt',
  'kaimo',
  'sania',
  'emilė',
  'nuoga',
  'times',
  'diską',
  'seksą',
  'donis',
  'dėkui',
  'puolė',
  'kerol',
  'faktą',
  'planų',
  'radot',
  'būkit',
  'baisi',
  'kietą',
  'rytas',
  'šimtų',
  'raili',
  'ferma',
  'tvyro',
  'manai',
  'sėdit',
  'alisa',
  'dikai',
  'arabų',
  'kaime',
  'pusių',
  'buvom',
  'nuėjo',
  'sūnau',
  'nerėk',
  'zinai',
  'rožių',
  'nieku',
  'dirbi',
  'sašos',
  'kairę',
  'rasiu',
  'palei',
  'įvyks',
  'dveji',
  'poros',
  'bijai',
  'kokią',
  'mažos',
  'matys',
  'vagys',
  'dydis',
  'žalia',
  'volis',
  'dikas',
  'vonia',
  'musės',
  'norma',
  'ponią',
  'garbė',
  'saška',
  'klubą',
  'ginti',
  'darbu',
  'eilės',
  'eisit',
  'švaru',
  'čakai',
  'jokie',
  'kodėl',
  'įdomu',
  'genai',
  'valtį',
  'idėjų',
  'skubi',
  'būsto',
  'metai',
  'baime',
  'kvapą',
  'verta',
  'senai',
  'filas',
  'taika',
  'gautų',
  'gėdos',
  'balsu',
  'kiekį',
  'andrė',
  'nueis',
  'galva',
  'turto',
  'keite',
  'night',
  'greit',
  'mokau',
  'įmonė',
  'aiški',
  'bėdos',
  'sienų',
  'tikrą',
  'britų',
  'jėzus',
  'atsuk',
  'lazda',
  'randu',
  'baimę',
  'benai',
  'dienų',
  'leisi',
  'sekit',
  'būrys',
  'lygūs',
  'gražų',
  'visus',
  'baldų',
  'spėju',
  'baimė',
  'baudą',
  'rango',
  'duktė',
  'vonią',
  'torto',
  'šauni',
  'kapus',
  'šeimą',
  'sidai',
  'imtis',
  'taškų',
  'tamsu',
  'dalys',
  'porno',
  'nagus',
  'tamsa',
  'žuvys',
  'ronai',
  'kovas',
  'namai',
  'mylių',
  'auklė',
  'esant',
  'įrašą',
  'šeštą',
  'banke',
  'šokių',
  'raktą',
  'įrodo',
  'galiu',
  'karto',
  'smūgį',
  'vėžlį',
  'taves',
  'duobę',
  'verki',
  'tikėk',
  'kinas',
  'snukį',
  'teisė',
  'laiku',
  'gaudo',
  'didis',
  'retas',
  'lauks',
  'mamai',
  'tetos',
  'valdo',
  'užeik',
  'kodėi',
  'lobių',
  'dugne',
  'kelią',
  'tokia',
  'uostą',
  'būklę',
  'sostą',
  'randa',
  'rikis',
  'dobby',
  'džois',
  'mirtį',
  'vėlai',
  'ribas',
  'fondą',
  'tamsi',
  'vadas',
  'šičia',
  'pilis',
  'vargo',
  'peidž',
  'skylę',
  'nervų',
  'judai',
  'galės',
  'dalių',
  'drąsą',
  'matot',
  'elkis',
  'įvyko',
  'pasak',
  'gauna',
  'rautų',
  'saugo',
  'tylus',
  'ataką',
  'jisai',
  'gaują',
  'sukti',
  'kąjūs',
  'neini',
  'dėžes',
  'žuvis',
  'vilko',
  'lieki',
  'lėtas',
  'baris',
  'paimt',
  'metro',
  'aukos',
  'bruno',
  'darau',
  'būsim',
  'vėjai',
  'bazai',
  'lenda',
  'svarų',
  'žaidi',
  'virvę',
  'scena',
  'vilkų',
  'galas',
  'darbų',
  'norai',
  'leist',
  'veino',
  'hobis',
  'manim',
  'progą',
  'sumos',
  'raktų',
  'šokit',
  'lokių',
  'kurie',
  'neras',
  'senos',
  'pėdas',
  'tūkst',
  'skanu',
  'nieko',
  'tapai',
  'šitam',
  'tokią',
  'knygą',
  'papai',
  'matau',
  'puola',
  'keise',
  'šauti',
  'plius',
  'baily',
  'filmų',
  'scott',
  'gumos',
  'išmok',
  'tašką',
  'šansų',
  'polas',
  'šalto',
  'rasim',
  'vieno',
  'tenai',
  'ranką',
  'gryno',
  'kursą',
  'vergų',
  'reiks',
  'skoti',
  'žemes',
  'tiktų',
  'įvykį',
  'grįžk',
  'nuodų',
  'filmą',
  'žiema',
  'skyrė',
  'gaunu',
  'neina',
  'vaikų',
  'parko',
  'kaukę',
  'džini',
  'galvų',
  'jiems',
  'protu',
  'brian',
  'meilę',
  'kalną',
  'sodas',
  'srovę',
  'būtum',
  'seife',
  'romai',
  'bures',
  'visur',
  'todel',
  'žolės',
  'šimts',
  'verti',
  'turėt',
  'vėjas',
  'džina',
  'kokio',
  'stalu',
  'veiki',
  'rėkti',
  'karių',
  'kėdės',
  'langą',
  'stora',
  'valdė',
  'kurti',
  'naujų',
  'laivu',
  'sakei',
  'geras',
  'kelių',
  'lauke',
  'akyse',
  'bylas',
  'kodel',
  'manot',
  'įtaką',
  'kalba',
  'vaiki',
  'įgulą',
  'taško',
  'buvau',
  'dievu',
  'siela',
  'spirk',
  'vesti',
  'ilgus',
  'kūnus',
  'tampi',
  'pašto',
  'slėpk',
  'nikas',
  'dainą',
  'mėšlu',
  'butas',
  'džino',
  'ringe',
  'juoko',
  'naomi',
  'labas',
  'gavęs',
  'mulki',
  'lekiu',
  'tikro',
  'liepė',
  'fredi',
  'rodos',
  'džile',
  'paėmė',
  'suims',
  'mainų',
  'šunis',
  'vietą',
  'įeina',
  'žymės',
  'pabūk',
  'formą',
  'davai',
  'semas',
  'žinai',
  'bomba',
  'todėl',
  'mielą',
  'vėžiu',
  'įeiti',
  'lenta',
  'uoste',
  'darys',
  'kepti',
  'nauja',
  'timai',
  'frodo',
  'rimti',
  'vedęs',
  'mažas',
  'vilas',
  'saugi',
  'žinių',
  'aktas',
  'ilgai',
  'jamči',
  'daręs',
  'žaidė',
  'burna',
  'dreba',
  'laikų',
  'verda',
  'video',
  'jauną',
  'esmės',
  'gerti',
  'namus',
  'dabar',
  'dingt',
  'žiedą',
  'karta',
  'marko',
  'manęs',
  'tenka',
  'mokėt',
  'rūbus',
  'norės',
  'kokiu',
  'ilgam',
  'stogo',
  'vesiu',
  'viens',
  'kalno',
  'kodai',
  'jauku',
  'įdėti',
  'kodas',
  'bijau',
  'pilni',
  'žiuri',
  'šitai',
  'šauna',
  'nauda',
  'rastų',
  'vertę',
  'kitas',
  'nueit',
  'laiko',
  'henry',
  'persi',
  'slepi',
  'galvą',
  'skonį',
  'viena',
  'žemas',
  'bobai',
  'rimtą',
  'bloke',
  'tylėk',
  'tegul',
  'tedis',
  'jeepo',
  'tyčia',
  'asmuo',
  'įpilk',
  'šneki',
  'liftą',
  'emile',
  'padėt',
  'vienu',
  'kojom',
  'švari',
  'laivo',
  'imsiu',
  'dukra',
  'varai',
  'šešių',
  'marta',
  'penki',
  'juodi',
  'stalą',
  'šitoj',
  'firma',
  'girta',
  'robai',
  'gamta',
  'balsą',
  'laiką',
  'šalis',
  'disko',
  'areną',
  'kilpą',
  'garsų',
  'jardų',
  'tempk',
  'gabus',
  'pirkt',
  'medis',
  'metla',
  'išėjo',
  'kaklą',
  'nuves',
  'iurek',
  'įkūrė',
  'renka',
  'house',
  'jėgas',
  'linda',
  'darbą',
  'miegi',
  'right',
  'ėmiau',
  'vilis',
  'purvo',
  'ritmą',
  'virto',
  'puses',
  'tarpu',
  'slava',
  'kartų',
  'ligos',
  'nieks',
  'duris',
  'lokys',
  'kvapo',
  'nojau',
  'klubo',
  'dirbu',
  'bylos',
  'šokti',
  'sakyt',
  'sakau',
  'leido',
  'graži',
  'sūris',
  'pieno',
  'taptų',
  'farai',
  'groja',
  'sėdėt',
  'bilai',
  'denio',
  'balsų',
  'dėlto',
  'rimta',
  'žymių',
  'kolia',
  'nuogi',
  'galių',
  'diržą',
  'heart',
  'tonas',
  'indus',
  'įrašo',
  'mesiu',
  'tuose',
  'čiupk',
  'įrašų',
  'mirus',
  'denis',
  'ieško',
  'pilną',
  'puiki',
  'suėmė',
  'garbę',
  'gavau',
  'veidą',
  'sergu',
  'šiems',
  'gatvę',
  'jezau',
  'upėje',
  'trejų',
  'kitoj',
  'mikis',
  'mesjė',
  'jamal',
  'setai',
  'atgal',
  'metju',
  'minią',
  'lukas',
  'gavai',
  'šneka',
  'bangų',
  'šimtą',
  'padės',
  'borto',
  'tapti',
  'lieku',
  'jūros',
  'metus',
  'šlovė',
  'šitos',
  'berni',
  'taip.',
  'prieš',
  'bombų',
  'lango',
  'nebūk',
  'lygis',
  'groji',
  'sukis',
  'aišku',
  'biuro',
  'matas',
  'viska',
  'baltą',
  'poste',
  'apima',
  'užėjo',
  'taurė',
  'kuper',
  'pačio',
  'kaulų',
  'plano',
  'tektų',
  'lovas',
  'karas',
  'miške',
  'mąsto',
  'srovė',
  'sapno',
  'stark',
  'diego',
  'žlugs',
  'klara',
  'kekše',
  'nešti',
  'tamsą',
  'kaltė',
  'šaukė',
  'kaina',
  'karvė',
  'gyvus',
  'senas',
  'kaimą',
  'ryšių',
  'vardą',
  'šunie',
  'dėžės',
  'žydai',
  'namas',
  'nuogą',
  'būdas',
  'rožės',
  'blogį',
  'kelio',
  'kampo',
  'tinka',
  'kitam',
  'dėdės',
  'akimi',
  'bėgti',
  'tėčiu',
  'gražu',
  'rodai',
  'laivų',
  'dujos',
  'golfo',
  'žaist',
  'groti',
  'truks',
  'kuzia',
  'šiame',
  'pykti',
  'tipas',
  'balso',
  'abiem',
  'salto',
  'žinom',
  'metas',
  'polai',
  'mirtų',
  'tylos',
  'veiks',
  'dokai',
  'kuria',
  'jauti',
  'gyvos',
  'įjunk',
  'vieni',
  'sunki',
  'kulka',
  'pietų',
  'proto',
  'nosis',
  'vargu',
  'laikė',
  'farus',
  'gaila',
  'vežti',
  'sielų',
  'ramūs',
  'dagai',
  'sofos',
  'galim',
  'metei',
  'amigo',
  'colių',
  'karti',
  'idėją',
  'bingo',
  'džimi',
  'ilgos',
  'sielą',
  'šūvis',
  'veidu',
  'grupę',
  'laimę',
  'augti',
  'šiaip',
  'knygų',
  'retai',
  'durys',
  'varau',
  'plauk',
  'vairo',
  'žiedo',
  'seifo',
  'frank',
  'bėkit',
  'čekis',
  'mokės',
  'bilio',
  'džoną',
  'leila',
  'katik',
  'rakto',
  'noras',
  'laimi',
  'proc.',
  'kelly',
  'šeron',
  'seifą',
  'sieki',
  'julia',
  'tedai',
  'narvą',
  'klasė',
  'eitum',
  'haris',
  'šunys',
  'kūnai',
  'rodyk',
  'gokai',
  'ežerą',
  'klaus',
  'atėmė',
  'laidų',
  'marti',
  'baras',
  'lėkti',
  'dings',
  'abejo',
  'būsiu',
  'rankų',
  'baisu',
  'kortą',
  'todas',
  'blogi',
  'sūriu',
  'kulkų',
  'vadui',
  'kuris',
  'likau',
  'tęsti',
  'užėmė',
  'veido',
  'sindi',
  'valgė',
  'mokam',
  'duoti',
  'dainų',
  'dingo',
  'bills',
  'mikai',
  'filai',
  'robas',
  'vince',
  'picos',
  'esate',
  'nakti',
  'grave',
  'dievo',
  'džeko',
  'žemėj',
  'grupė',
  'šikna',
  'lilia',
  'kenas',
  'kalbi',
  'tėvai',
  'bokso',
  'cento',
  'džule',
  'batus',
  'kardu',
  'žalią',
  'savas',
  'ilgio',
  'arklį',
  'eisiu',
  'karui',
  'pilvą',
  'labui',
  'viduj',
  'duona',
  'papus',
  'tikru',
  'rikai',
  'košės',
  'lygos',
  'kelis',
  'matėt',
  'timas',
  'paima',
  'trims',
  'gėjus',
  'darot',
  'helen',
  'makso',
  'naštą',
  'ninos',
  'žinia',
  'aukso',
  'saulė',
  'ledus',
  'baigė',
  'veidų',
  'rikas',
  'laimė',
  'džono',
  'vardų',
  'nulis',
  'sunkų',
  'maišą',
  'šalia',
  'džoja',
  'sūnus',
  'kenis',
  'lapai',
  'sapną',
  'matyt',
  'rinka',
  'gėjai',
  'kalta',
  'jomis',
  'pučia',
  'sakyk',
  'kuriu',
  'misis',
  'sieja',
  'broli',
  'iabai',
  'lazlo',
  'temos',
  'vagių',
  'šitie',
  'narių',
  'henri',
  'vilki',
  'mitas',
  'salėj',
  'tarpe',
  'vardo',
  'kavos',
  'grožį',
  'kieno',
  'meniu',
  'dedas',
  'rūšis',
  'šalmą',
  'cirko',
  'viršų',
  'tiesa',
  'panos',
  'pusėj',
  'tęsis',
  'sekas',
  'romeo',
  'ateik',
  'laivą',
  'kaire',
  'harvi',
  'nepyk',
  'sonai',
  'denai',
  'bankų',
  'sesei',
  'prašė',
  'dukrą',
  'kalnų',
  'šilta',
  'senus',
  'tokie',
  'anūką',
  'kemai',
  'karai',
  'valio',
  'savim',
  'teisę',
  'dėtas',
  'pilna',
  'kenai',
  'miego',
  'duotų',
  'laike',
  'trenk',
  'eisim',
  'geria',
  'klydo',
  'jauni',
  'grąžą',
  'garsą',
  'kitai',
  'frazė',
  'mėsos',
  'kilęs',
  'žavus',
  'bobis',
  'patys',
  'tiltą',
  'tevas',
  'brolį',
  'kokia',
  'edita',
  'ryžių',
  'vaiką',
  'pirtį',
  'tortą',
  'omeny',
  'lėtai',
  'auksą',
  'sėkmę',
  'laužo',
  'višta',
  'gryna',
  'žiemą',
  'ausis',
  'senam',
  'rodyt',
  'pačių',
  'šansą',
  'judam',
  'bėdas',
  'bravo',
  'saros',
  'varge',
  'eiles',
  'atneš',
  'gausi',
  'džene',
  'meras',
  'zonos',
  'testo',
  'lipti',
  'ugnis',
  'vyktų',
  'noriu',
  'blogų',
  'knyga',
  'lieka',
  'kilpa',
  'marli',
  'einas',
  'spyną',
  'mažai',
  'super',
  'antrą',
  'pitai',
  'fredo',
  'parką',
  'tautų',
  'porai',
  'mėšlą',
  'žinot',
  'nanos',
  'ledai',
  'šlovę',
  'aklas',
  'tostą',
  'tėtis',
  'peili',
  'semis',
  'pirmi',
  'mokat',
  'norus',
  'tetis',
  'kąjis',
  'norim',
  'prekė',
  'manei',
  'lovos',
  'tomis',
  'žudai',
  'liuse',
  'ateis',
  'vairą',
  'ponia',
  'tania',
  'liuką',
  'siena',
  'kylam',
  'dirbt',
  'visom',
  'grįžt',
  'mumis',
  'laive',
  'visai',
  'matom',
  'džine',
  'šimto',
  'pusės',
  'sekso',
  'irake',
  'uždėk',
  'visam',
  'meška',
  'skylė',
  'tiksi',
  'kurso',
  'karen',
  'luiza',
  'dievą',
  'senia',
  'vaikį',
  'diena',
  'miela',
  'miško',
  'lapus',
  'džeką',
  'laužą',
  'kekšė',
  'nuimk',
  'liuda',
  'gonna',
  'tapsi',
  'jumis',
  'kairė',
  'kątik',
  'baisų',
  'krito',
  'muilo',
  'bijot',
  'nejau',
  'keitė',
  'žirgo',
  'atima',
  'maiko',
  'aukas',
  'stumk',
  'alaus',
  'gauti',
  'serga',
  'bilas',
  'lifte',
  'žlugo',
  'dirbs',
  'esame',
  'turgų',
  'dūmai',
  'vyrui',
  'rėkia',
  'tolyn',
  'grįžo',
  'kojas',
  'miegą',
  'darba',
  'šeimų',
  'žalio',
  'lekia',
  'išeik',
  'garso',
  'ianai',
  'kojos',
  'tiesų',
  'kampą',
  'kortų',
  'įkišo',
  'bičai',
  'bakai',
  'kalbą',
  'veikt',
  'lygus',
  'sėsti',
  'jėgos',
  'dzune',
  'baksų',
  'rodys',
  'lemta',
  'laika',
  'žiūri',
  'žinią',
  'pulti',
  'sekti',
  'idėja',
  'harry',
  'vidun',
  'pusei',
  'ėmėsi',
  'daryt',
  'vėžys',
  'juodą',
  'skydo',
  'ryšio',
  'šiose',
  'saulę',
  'aleks',
  'kitos',
  'nulio',
  'ponai',
  'protą',
]
