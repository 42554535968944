export const VALIDGUESSES = [
  'abate',
  'abeli',
  'abelį',
  'about',
  'abudu',
  'adamo',
  'adams',
  'adamu',
  'adamą',
  'adata',
  'adatą',
  'adatų',
  'adios',
  'adler',
  'adomo',
  'adoni',
  'again',
  'agata',
  'aglou',
  'ahmet',
  'aidas',
  'aikai',
  'airio',
  'airis',
  'airių',
  'aisku',
  'aislu',
  'aisti',
  'aiškų',
  'ajova',
  'ak-47',
  'akaba',
  'akabą',
  'akbar',
  'akiai',
  'akims',
  'aklai',
  'aklos',
  'akyje',
  'alamo',
  'alanu',
  'alaną',
  'albin',
  'albis',
  'aleno',
  'aleną',
  'alesa',
  'alibi',
  'alice',
  'alina',
  'alisą',
  'alkio',
  'alkis',
  'alkos',
  'allen',
  'aloha',
  'alone',
  'alyva',
  'alyvą',
  'alyvų',
  'alėja',
  'alėją',
  'amane',
  'amara',
  'amato',
  'amber',
  'amziu',
  'anais',
  'aname',
  'andre',
  'andže',
  'angai',
  'angas',
  'angel',
  'angle',
  'anglu',
  'anglą',
  'anglį',
  'angos',
  'anios',
  'anita',
  'annie',
  'anoje',
  'anoks',
  'antis',
  'antri',
  'antru',
  'antrų',
  'antys',
  'anūko',
  'anūku',
  'anūkė',
  'anūkų',
  'apart',
  'apeik',
  'apolo',
  'apple',
  'april',
  'apėjo',
  'apėmė',
  'arena',
  'ariel',
  'arija',
  'arijò',
  'arijų',
  'arjis',
  'arkly',
  'arkos',
  'armij',
  'arono',
  'aronu',
  'aroną',
  'artai',
  'artis',
  'artyn',
  'arven',
  'arčer',
  'ashli',
  'asilo',
  'asilu',
  'asilą',
  'astma',
  'aston',
  'atakų',
  'atari',
  'atauš',
  'atejo',
  'atgai',
  'atimi',
  'atimk',
  'atimt',
  'atimu',
  'atlik',
  'atomo',
  'atomą',
  'atomų',
  'atras',
  'atves',
  'atveš',
  'atvyk',
  'atėję',
  'atėnė',
  'atėnų',
  'au-au',
  'audio',
  'audrų',
  'augau',
  'auglį',
  'augtų',
  'aukai',
  'auklę',
  'aukse',
  'auksu',
  'ausim',
  'aušra',
  'aušrą',
  'aušta',
  'avada',
  'averi',
  'avery',
  'avies',
  'avilį',
  'avine',
  'avino',
  'aviną',
  'avėti',
  'aziją',
  'azoto',
  'azuma',
  'ačiū.',
  'aš-aš',
  'ašara',
  'ašarą',
  'ašies',
  'aštri',
  'aštrų',
  'badai',
  'badas',
  'badis',
  'baigi',
  'baigę',
  'baili',
  'bailė',
  'bailį',
  'bakas',
  'baker',
  'bakis',
  'bakli',
  'balai',
  'balik',
  'balių',
  'balno',
  'balos',
  'balsa',
  'balse',
  'baltu',
  'baltų',
  'bamba',
  'banda',
  'bandą',
  'bangą',
  'banka',
  'banks',
  'banku',
  'barbe',
  'barbė',
  'bario',
  'barni',
  'barry',
  'barty',
  'barus',
  'barža',
  'baržą',
  'basas',
  'batas',
  'baton',
  'baudų',
  'baugu',
  'bazei',
  'bazes',
  'bazių',
  'bazėj',
  'beach',
  'bebre',
  'begam',
  'beili',
  'being',
  'beino',
  'beiną',
  'beits',
  'bekai',
  'bekas',
  'belen',
  'belgų',
  'bells',
  'belos',
  'bembi',
  'benet',
  'benio',
  'benis',
  'benks',
  'benui',
  'benži',
  'beris',
  'berną',
  'bernį',
  'berta',
  'berti',
  'betei',
  'betgi',
  'betos',
  'betės',
  'bijok',
  'bijom',
  'bijos',
  'bilal',
  'bilbo',
  'biliu',
  'billy',
  'bilui',
  'bimiš',
  'birdi',
  'birža',
  'bites',
  'biuru',
  'biznį',
  'bičių',
  'bičui',
  'bičus',
  'biški',
  'black',
  'blakę',
  'bleit',
  'block',
  'blogu',
  'bloką',
  'blokų',
  'blusų',
  'blynų',
  'bobby',
  'bobio',
  'bobom',
  'bobui',
  'boiro',
  'boksą',
  'bondo',
  'bondą',
  'boras',
  'bordo',
  'boria',
  'borno',
  'bornu',
  'borną',
  'bortą',
  'bosai',
  'bosus',
  'boudi',
  'bouni',
  'braga',
  'bragą',
  'brand',
  'braun',
  'break',
  'bredo',
  'bredą',
  'bredį',
  'britl',
  'britu',
  'britė',
  'brody',
  'brown',
  'bruce',
  'budas',
  'budis',
  'budos',
  'bugle',
  'bukai',
  'bulio',
  'bulma',
  'bulve',
  'bulvė',
  'bunda',
  'burių',
  'burnų',
  'burtą',
  'burtų',
  'burys',
  'busim',
  'butai',
  'butui',
  'butum',
  'butus',
  'buves',
  'buvus',
  'bučai',
  'bučkį',
  'bušas',
  'bybis',
  'bylai',
  'byloj',
  'bynas',
  'byčas',
  'bėgai',
  'bėgat',
  'bėglį',
  'bėgom',
  'bėgsi',
  'bėjau',
  'bėkim',
  'būdai',
  'būdui',
  'būdus',
  'būgnų',
  'būkim',
  'būnai',
  'būnas',
  'būriu',
  'būrių',
  'būstą',
  'būtas',
  'būvau',
  'c-130',
  'caras',
  'carly',
  'cause',
  'ceini',
  'cekai',
  'centu',
  'centą',
  'chaki',
  'chana',
  'chano',
  'chari',
  'chase',
  'check',
  'chick',
  'chore',
  'choro',
  'chorą',
  'chosė',
  'chris',
  'chuck',
  'ciklo',
  'ciklą',
  'cirke',
  'cirku',
  'clear',
  'colio',
  'cooze',
  'could',
  'count',
  'creek',
  'cukrų',
  'daddy',
  'dafis',
  'dafne',
  'dagui',
  'daido',
  'daili',
  'daily',
  'dalai',
  'dalia',
  'dalim',
  'damai',
  'damas',
  'danai',
  'danas',
  'danka',
  'dante',
  'darla',
  'daros',
  'darpa',
  'darsi',
  'darąs',
  'darėt',
  'darže',
  'datas',
  'datos',
  'daubą',
  'dauni',
  'daužo',
  'daužė',
  'david',
  'davis',
  'davus',
  'davėm',
  'davėt',
  'davęs',
  'dažau',
  'dažna',
  'dažnį',
  'dažus',
  'dažyt',
  'death',
  'debra',
  'dedam',
  'degam',
  'degsi',
  'degti',
  'deina',
  'deive',
  'deivi',
  'deivo',
  'deivu',
  'deivą',
  'deivę',
  'deizę',
  'dejah',
  'dekui',
  'della',
  'delne',
  'delno',
  'delną',
  'delsi',
  'denas',
  'dengė',
  'deniu',
  'dento',
  'dentą',
  'denui',
  'derek',
  'dešra',
  'dešrą',
  'diege',
  'diegą',
  'dieta',
  'dieva',
  'dikio',
  'dikis',
  'dimai',
  'dimos',
  'dinai',
  'diržo',
  'diržu',
  'diržų',
  'diske',
  'diskų',
  'dičkį',
  'dobis',
  'dodas',
  'doesn',
  'doing',
  'dojis',
  'dokas',
  'domas',
  'donna',
  'donos',
  'doors',
  'dorai',
  'doras',
  'doris',
  'doros',
  'dosni',
  'dosnu',
  'dotar',
  'dozės',
  'dragą',
  'drama',
  'drebi',
  'drebu',
  'drive',
  'drogo',
  'droną',
  'dronų',
  'drovi',
  'drožk',
  'drąsu',
  'drąsų',
  'drįso',
  'dukrų',
  'duobė',
  'duoną',
  'duota',
  'duoto',
  'duotą',
  'duria',
  'durim',
  'durna',
  'durti',
  'dusti',
  'dusyk',
  'dučai',
  'dušas',
  'dvare',
  'dvaro',
  'dvarą',
  'dvejų',
  'dvoki',
  'dvoko',
  'dvoką',
  'dvokė',
  'dvynė',
  'dvynę',
  'dvėsk',
  'dykai',
  'dylan',
  'dynai',
  'dynas',
  'dzeri',
  'dčkui',
  'dėjai',
  'dėkit',
  'dėkok',
  'dėkot',
  'dėlei',
  'dėmes',
  'dėmių',
  'dėmės',
  'dėsim',
  'dėsis',
  'dėsto',
  'dėtis',
  'dėtos',
  'dėviu',
  'dėžėj',
  'dūmas',
  'dūmus',
  'dūrio',
  'dūsta',
  'dūstu',
  'dūžta',
  'džamp',
  'džefo',
  'džein',
  'džeke',
  'džeku',
  'džekė',
  'džemi',
  'džena',
  'dženy',
  'dženę',
  'džerį',
  'džesė',
  'džesį',
  'džilė',
  'džimo',
  'džimu',
  'džimą',
  'džimį',
  'džinu',
  'džiną',
  'džinė',
  'džipą',
  'džodi',
  'džoji',
  'džoli',
  'džona',
  'džonį',
  'džošo',
  'džošu',
  'džošą',
  'džude',
  'džuli',
  'džulė',
  'džulę',
  'džune',
  'džung',
  'džėjų',
  'eagle',
  'eddie',
  'edgar',
  'edžio',
  'edžiu',
  'eibai',
  'eibas',
  'eifel',
  'eilių',
  'eilėj',
  'eimei',
  'eimos',
  'eimės',
  'eisis',
  'eismą',
  'eleną',
  'elfai',
  'elfas',
  'eliot',
  'elisa',
  'elise',
  'elito',
  'elizą',
  'ellen',
  'ellis',
  'elnio',
  'elnią',
  'elvio',
  'elvis',
  'ember',
  'emeri',
  'emilę',
  'encom',
  'endže',
  'endži',
  'endžė',
  'enter',
  'erdve',
  'ereli',
  'erife',
  'erika',
  'eriko',
  'eriku',
  'eriką',
  'erlai',
  'erlas',
  'ernis',
  'ertmę',
  'espen',
  'ester',
  'esybę',
  'etape',
  'etapo',
  'etapą',
  'etato',
  'etery',
  'eterį',
  'etika',
  'etiką',
  'etsuo',
  'evers',
  'every',
  'evlin',
  'ešlin',
  'ešlis',
  'ešlės',
  'ežere',
  'failą',
  'failų',
  'faina',
  'fakso',
  'faksu',
  'faksą',
  'faktu',
  'faktų',
  'falls',
  'fanai',
  'fanas',
  'faren',
  'farui',
  'fazės',
  'fašas',
  'fedai',
  'fenas',
  'fengo',
  'fenio',
  'feris',
  'fermą',
  'fermų',
  'fidži',
  'fiima',
  'filds',
  'filis',
  'filma',
  'films',
  'filmu',
  'filui',
  'finai',
  'finas',
  'finli',
  'finčo',
  'firmą',
  'firmų',
  'first',
  'fišai',
  'fišer',
  'fjuša',
  'fleur',
  'flino',
  'foksi',
  'fomos',
  'fonas',
  'fondų',
  'force',
  'fordo',
  'formų',
  'forte',
  'forto',
  'fortą',
  'frato',
  'frazę',
  'fredu',
  'fredą',
  'fredį',
  'frito',
  'frėja',
  'fubar',
  'furis',
  'fėjas',
  'fėjos',
  'gabse',
  'gaidy',
  'gaiim',
  'gaiiu',
  'gaišt',
  'gajau',
  'gajus',
  'galai',
  'galop',
  'galui',
  'galus',
  'galįs',
  'gamai',
  'gamtą',
  'gandą',
  'gandų',
  'gantu',
  'garai',
  'garbe',
  'gardo',
  'gardu',
  'gario',
  'garsi',
  'garsu',
  'gasas',
  'gaujų',
  'gauną',
  'gausu',
  'gauta',
  'gavus',
  'gazza',
  'geary',
  'geeks',
  'geile',
  'geili',
  'geilė',
  'gelai',
  'gelia',
  'genas',
  'genda',
  'gendi',
  'genos',
  'gentį',
  'genus',
  'genys',
  'geoff',
  'geram',
  'gerb.',
  'gerbi',
  'gerbk',
  'gerbs',
  'gerbt',
  'gerbė',
  'gerda',
  'geroj',
  'gerte',
  'ghoul',
  'gidas',
  'gieda',
  'gilai',
  'gilas',
  'gilda',
  'giliu',
  'gilią',
  'gilus',
  'gilyn',
  'gimda',
  'gimli',
  'gimti',
  'gimtų',
  'gimus',
  'ginko',
  'ginny',
  'ginčo',
  'ginčą',
  'ginčų',
  'gipsy',
  'giria',
  'girią',
  'girls',
  'girti',
  'girto',
  'girtą',
  'girtų',
  'glenu',
  'globa',
  'globe',
  'globo',
  'globą',
  'glėbį',
  'glūdi',
  'gnomą',
  'gnomų',
  'godus',
  'godūs',
  'gojos',
  'gokui',
  'golfą',
  'golum',
  'gorvi',
  'gotas',
  'gotta',
  'gošai',
  'gošos',
  'grabo',
  'grace',
  'grady',
  'grafe',
  'grafo',
  'gralį',
  'gramo',
  'gramų',
  'grass',
  'grazu',
  'great',
  'green',
  'grego',
  'gregu',
  'greis',
  'grifo',
  'grimo',
  'gripo',
  'gripu',
  'griša',
  'griūk',
  'grižo',
  'grobį',
  'groju',
  'grosi',
  'group',
  'grubi',
  'grubu',
  'grybų',
  'gryną',
  'grynų',
  'gryžk',
  'grąža',
  'grėsė',
  'grįšk',
  'grįžę',
  'grūdų',
  'guamą',
  'gucci',
  'gudri',
  'gudru',
  'gulbė',
  'guliu',
  'gulti',
  'gulto',
  'gultą',
  'gulės',
  'gulėt',
  'gumbo',
  'guolį',
  'gupta',
  'gusai',
  'gydau',
  'gydyt',
  'gylio',
  'gylis',
  'gynei',
  'gysla',
  'gyuri',
  'gyvam',
  'gėjum',
  'gėlėm',
  'gėris',
  'gėrėm',
  'gėrėt',
  'gėręs',
  'gęsta',
  'gūžtą',
  'hablo',
  'hadai',
  'hadas',
  'haiku',
  'halai',
  'halką',
  'halsi',
  'hamai',
  'hanas',
  'hanos',
  'hanzi',
  'hanzo',
  'happy',
  'hariu',
  'harle',
  'harli',
  'harue',
  'harvį',
  'hekai',
  'hekas',
  'helai',
  'helio',
  'helis',
  'hello',
  'helmo',
  'hemai',
  'henko',
  'henrį',
  'herli',
  'heron',
  'heter',
  'hidra',
  'hidro',
  'hiena',
  'hilas',
  'hilde',
  'hilse',
  'himną',
  'hiper',
  'hipių',
  'hitas',
  'hičai',
  'hičas',
  'hobbs',
  'holas',
  'holis',
  'holms',
  'houmi',
  'houpo',
  'houpą',
  'hudas',
  'huper',
  'hyatt',
  'hydra',
  'iaiko',
  'ianas',
  'ideja',
  'idomu',
  'ieisk',
  'ieiti',
  'ietis',
  'ievai',
  'igori',
  'ijone',
  'ijonė',
  'ikart',
  'ikrai',
  'ikrus',
  'ilgis',
  'iltie',
  'iltis',
  'imame',
  'imasi',
  'imate',
  'imkim',
  'imsim',
  'imsis',
  'imsit',
  'indai',
  'indas',
  'inesa',
  'inojo',
  'inote',
  'inoti',
  'ipodą',
  'irako',
  'iraką',
  'irane',
  'irano',
  'irena',
  'irene',
  'irina',
  'irjis',
  'irklu',
  'isaac',
  'isaak',
  'isejo',
  'itaip',
  'iuoja',
  'iuosi',
  'iuoti',
  'ivano',
  'ivyko',
  'ivyks',
  'izumi',
  'išauš',
  'išimk',
  'išimt',
  'iširo',
  'išlik',
  'išlys',
  'išlįs',
  'išmuš',
  'išnyk',
  'išore',
  'išorė',
  'išorę',
  'išves',
  'išveš',
  'išvyk',
  'išvys',
  'išėję',
  'jaigu',
  'james',
  'jamčį',
  'janai',
  'janas',
  'janis',
  'jardu',
  'jason',
  'jauką',
  'jauno',
  'jaunu',
  'jaunų',
  'jausi',
  'jausk',
  'jaust',
  'jautė',
  'jenny',
  'jerry',
  'jezus',
  'jimas',
  'jimmy',
  'jo-jo',
  'jogis',
  'jogos',
  'jojam',
  'jojau',
  'jokią',
  'jonai',
  'jonas',
  'jondu',
  'jones',
  'jonuk',
  'jorke',
  'jorko',
  'jorką',
  'josiu',
  'judas',
  'judat',
  'judės',
  'judėt',
  'junco',
  'juodo',
  'juodu',
  'juodų',
  'juoką',
  'juokų',
  'juros',
  'justi',
  'jutau',
  'jutos',
  'jėgai',
  'jėzui',
  'jūmis',
  'jūras',
  'jūroj',
  'kablį',
  'kabės',
  'kadre',
  'kadro',
  'kadrą',
  'kadrų',
  'kaiba',
  'kaifo',
  'kaijų',
  'kaika',
  'kaiko',
  'kailo',
  'kailu',
  'kailą',
  'kaimų',
  'kainų',
  'kairo',
  'kairą',
  'kairį',
  'kakle',
  'kakta',
  'kalai',
  'kalas',
  'kalei',
  'kales',
  'kalio',
  'kalis',
  'kalių',
  'kalne',
  'kalnu',
  'kalte',
  'kalva',
  'kalvą',
  'kalvų',
  'kamar',
  'kampa',
  'kampų',
  'kanda',
  'kandi',
  'kaoru',
  'kapai',
  'karak',
  'kardų',
  'kariu',
  'karla',
  'karle',
  'karli',
  'karlo',
  'karlu',
  'karlą',
  'karlė',
  'karma',
  'karts',
  'karus',
  'karve',
  'karvę',
  'kasos',
  'katei',
  'kates',
  'katie',
  'katią',
  'kauls',
  'kaulu',
  'kaulą',
  'kazka',
  'kaątu',
  'kažka',
  'keblu',
  'kedrų',
  'keila',
  'keile',
  'keili',
  'keisi',
  'keisk',
  'keisė',
  'keiti',
  'keitę',
  'kekšę',
  'kelas',
  'keler',
  'kelmo',
  'kelto',
  'keltu',
  'keltą',
  'keltų',
  'kelės',
  'kemas',
  'kemša',
  'kende',
  'kendi',
  'kentė',
  'kepta',
  'keptą',
  'keptų',
  'kerai',
  'keris',
  'kerpa',
  'kerta',
  'kertu',
  'kerus',
  'kerės',
  'ketne',
  'ketnė',
  'kevai',
  'kevin',
  'kešas',
  'khano',
  'khaną',
  'kidai',
  'kieto',
  'kietu',
  'kietų',
  'kilis',
  'kilmė',
  'kilmę',
  'kilni',
  'kilnu',
  'kilnų',
  'kiltų',
  'kilus',
  'kimai',
  'kimas',
  'kimba',
  'kinai',
  'kingo',
  'kings',
  'kingą',
  'kinti',
  'kinto',
  'kinus',
  'kioko',
  'kipai',
  'kipas',
  'kirbe',
  'kirbi',
  'kirpk',
  'kirpt',
  'kirsk',
  'kirti',
  'kirto',
  'kirvį',
  'kitką',
  'kitom',
  'kiuri',
  'kišti',
  'klane',
  'klanu',
  'klanà',
  'klaną',
  'klanų',
  'klarą',
  'kleir',
  'klerė',
  'klevo',
  'klijų',
  'klipe',
  'klipą',
  'klitc',
  'klius',
  'kloja',
  'klubu',
  'klubų',
  'klykė',
  'klyną',
  'knisa',
  'knoks',
  'kobai',
  'kobas',
  'kobra',
  'kobrą',
  'kodei',
  'kodis',
  'kodél',
  'kodël',
  'kodól',
  'kodčl',
  'kodģl',
  'kodži',
  'koeno',
  'kohen',
  'kojai',
  'koksa',
  'kokso',
  'koksu',
  'koksą',
  'kolai',
  'kolos',
  'komos',
  'kompo',
  'kompu',
  'kompą',
  'konge',
  'kongo',
  'kongą',
  'konio',
  'konor',
  'kopas',
  'kopti',
  'korso',
  'korta',
  'koryo',
  'kosmo',
  'kosta',
  'kotas',
  'koulo',
  'kovoj',
  'kovot',
  'krabe',
  'krabo',
  'krabą',
  'krabų',
  'krano',
  'kratą',
  'kraut',
  'krein',
  'kreko',
  'kremo',
  'kremu',
  'kremą',
  'kridi',
  'krise',
  'krisi',
  'krisk',
  'kriso',
  'krisu',
  'krisą',
  'krize',
  'krizę',
  'krust',
  'kruta',
  'kruva',
  'kruša',
  'krydo',
  'krūmą',
  'krūmų',
  'krūtį',
  'krūtų',
  'kubas',
  'kubos',
  'kukas',
  'kukli',
  'kuklų',
  'kulnų',
  'kumpį',
  'kunas',
  'kuolo',
  'kuolą',
  'kuopa',
  'kupas',
  'kuras',
  'kurià',
  'kurmi',
  'kurse',
  'kursu',
  'kurtu',
  'kurtą',
  'kurva',
  'kuzma',
  'kužda',
  'kvapu',
  'kvapų',
  'kvitą',
  'kvitų',
  'kvyne',
  'kytai',
  'kyšis',
  'kąjie',
  'kąsnį',
  'kėdes',
  'kėdėj',
  'kėjus',
  'kęsti',
  'kūjis',
  'kūnui',
  'labia',
  'laden',
  'laido',
  'laila',
  'laime',
  'laiva',
  'laivł',
  'laižo',
  'lakas',
  'lambo',
  'lamos',
  'landi',
  'langa',
  'lange',
  'langu',
  'lanku',
  'lanką',
  'lankė',
  'lančė',
  'lapas',
  'lapės',
  'lario',
  'larry',
  'latte',
  'lauka',
  'lauku',
  'laukų',
  'lavos',
  'lašai',
  'lašas',
  'lašus',
  'leave',
  'ledis',
  'leidę',
  'leiko',
  'leise',
  'leksi',
  'leman',
  'lemia',
  'lempa',
  'lempų',
  'lemtį',
  'lenai',
  'lendi',
  'lendu',
  'lenis',
  'leniu',
  'lenki',
  'lenkų',
  'lenos',
  'lentų',
  'leoni',
  'leple',
  'lesai',
  'lesbe',
  'lesle',
  'lesli',
  'leslė',
  'letai',
  'letty',
  'levai',
  'levas',
  'levis',
  'lewis',
  'lexus',
  'liana',
  'liepa',
  'liepe',
  'liepi',
  'lieps',
  'liepą',
  'liesa',
  'liesi',
  'liest',
  'lieti',
  'lietė',
  'lietų',
  'liftu',
  'liftų',
  'ligas',
  'light',
  'lijau',
  'likim',
  'likom',
  'lilią',
  'lilya',
  'limos',
  'limpa',
  'lindi',
  'lindo',
  'lindą',
  'linki',
  'linkę',
  'liošą',
  'lipau',
  'lipni',
  'lipsi',
  'litro',
  'litrą',
  'litrų',
  'liuba',
  'liuko',
  'liuks',
  'liusę',
  'lizai',
  'lizde',
  'lizdo',
  'lizos',
  'liūdi',
  'liūte',
  'liūto',
  'liūtą',
  'liūtė',
  'liūtų',
  'llgai',
  'llgus',
  'lobio',
  'logai',
  'lokai',
  'lokas',
  'lokis',
  'lokiu',
  'longo',
  'lorai',
  'lordu',
  'lordą',
  'lordų',
  'lorel',
  'loren',
  'lorna',
  'louis',
  'lovon',
  'lowry',
  'lošia',
  'lošiu',
  'lošti',
  'lubas',
  'lubos',
  'lucky',
  'luisa',
  'luise',
  'luiso',
  'luisą',
  'luizą',
  'lujis',
  'luomo',
  'lycan',
  'lydės',
  'lygiu',
  'lygių',
  'lygtį',
  'lynas',
  'lynch',
  'lynet',
  'lynus',
  'lytis',
  'lėjus',
  'lėles',
  'lėlių',
  'lėlės',
  'lėšas',
  'lėšos',
  'lįsti',
  'lūšna',
  'lūšną',
  'lūžio',
  'lūžis',
  'lūžių',
  'lūžta',
  'ma-ma',
  'magai',
  'magda',
  'maikl',
  'maiku',
  'maiką',
  'mailo',
  'maiše',
  'maišo',
  'maišu',
  'maišų',
  'majai',
  'makas',
  'maksu',
  'maksą',
  'malda',
  'maldų',
  'malik',
  'malkų',
  'malti',
  'mamas',
  'mamba',
  'mambo',
  'mamyt',
  'manas',
  'mango',
  'mania',
  'manis',
  'manny',
  'manom',
  'mantą',
  'manyt',
  'manzo',
  'manėt',
  'manņs',
  'manźs',
  'maria',
  'mario',
  'marių',
  'marki',
  'marku',
  'marką',
  'marla',
  'marse',
  'marsi',
  'marso',
  'marsą',
  'marsė',
  'martą',
  'martį',
  'marvi',
  'masto',
  'mastu',
  'masės',
  'mates',
  'matju',
  'maxie',
  'maybe',
  'mazas',
  'mazel',
  'mazgu',
  'mazgą',
  'mačas',
  'mažam',
  'mažom',
  'media',
  'medum',
  'medus',
  'megės',
  'meine',
  'meino',
  'mekai',
  'melai',
  'meldė',
  'melsk',
  'melus',
  'mendi',
  'menis',
  'menka',
  'menki',
  'menką',
  'mentų',
  'menui',
  'menus',
  'merci',
  'mercy',
  'merei',
  'merfė',
  'merfį',
  'mergą',
  'mergų',
  'merry',
  'mersi',
  'merui',
  'merve',
  'merės',
  'mesim',
  'mesje',
  'mestų',
  'metam',
  'metru',
  'metrą',
  'metui',
  'metėt',
  'metęs',
  'mešką',
  'meškų',
  'midai',
  'midas',
  'mielo',
  'mielu',
  'mielų',
  'migla',
  'miglą',
  'mijos',
  'mikas',
  'mikio',
  'mikiu',
  'mikki',
  'mikos',
  'miles',
  'mille',
  'mills',
  'miltų',
  'minas',
  'mindi',
  'minos',
  'minta',
  'minti',
  'minty',
  'minės',
  'mirei',
  'mirta',
  'misjo',
  'mitai',
  'mitus',
  'mitzi',
  'mičai',
  'mičas',
  'miškų',
  'mlrd.',
  'mm-hm',
  'mogus',
  'moira',
  'mokai',
  'mokei',
  'mokys',
  'mokyt',
  'mokėk',
  'molio',
  'molis',
  'molly',
  'monai',
  'mones',
  'monet',
  'money',
  'moniu',
  'monro',
  'monte',
  'moody',
  'moore',
  'morge',
  'morgą',
  'morin',
  'moris',
  'morka',
  'morką',
  'morkų',
  'morti',
  'motel',
  'mount',
  'movie',
  'mozli',
  'mozės',
  'močai',
  'mpudi',
  'mudai',
  'mudas',
  'muilu',
  'muilą',
  'mulas',
  'mulen',
  'mulkė',
  'mulkį',
  'mulle',
  'murma',
  'murėj',
  'musai',
  'muses',
  'music',
  'musių',
  'mušiu',
  'myiiu',
  'mylia',
  'mylit',
  'mylią',
  'mylėk',
  'mylės',
  'mylėt',
  'myžti',
  'mėgai',
  'mėgau',
  'mėgti',
  'mėjau',
  'mėjus',
  'mėsai',
  'mėtai',
  'mėtyt',
  'mėšle',
  'mįslė',
  'mįslę',
  'mūmis',
  'mūras',
  'mūzai',
  'mūšių',
  'nacis',
  'naciu',
  'naciò',
  'nacių',
  'nadia',
  'naftą',
  'nagai',
  'nagas',
  'nagi.',
  'nahui',
  'naivi',
  'nakty',
  'naktų',
  'namui',
  'nanai',
  'napos',
  'narai',
  'naras',
  'nardų',
  'nario',
  'nariò',
  'narkė',
  'narsa',
  'narsi',
  'narsą',
  'narvo',
  'natas',
  'našle',
  'našlė',
  'našlę',
  'nebuk',
  'nebėk',
  'nebėr',
  'nebūt',
  'nedas',
  'nedėk',
  'neesi',
  'negre',
  'negro',
  'negru',
  'negrų',
  'neigi',
  'neima',
  'neimi',
  'neims',
  'neimu',
  'neinu',
  'neiti',
  'neitų',
  'nekai',
  'nekas',
  'nekęs',
  'nense',
  'nensi',
  'nensė',
  'neria',
  'nerti',
  'nesat',
  'nesek',
  'nesuk',
  'netik',
  'never',
  'neves',
  'nexus',
  'neėda',
  'neėmė',
  'nešam',
  'nešas',
  'nešei',
  'nešiu',
  'nešok',
  'nežus',
  'nieke',
  'nigga',
  'nikis',
  'nikki',
  'nikol',
  'nikui',
  'nilai',
  'nilas',
  'ninai',
  'niobe',
  'niūri',
  'noeli',
  'nojus',
  'norad',
  'noris',
  'norių',
  'normą',
  'normų',
  'north',
  'norėt',
  'nosim',
  'novis',
  'novos',
  'ns-5s',
  'nubėk',
  'nuims',
  'nuimt',
  'nuimu',
  'nukry',
  'numes',
  'nuneš',
  'nuogo',
  'nuogų',
  'nuoma',
  'nuryk',
  'nuėję',
  'nuėmė',
  'nyiai',
  'nylai',
  'nyris',
  'nėjai',
  'nėjau',
  'obama',
  'obels',
  'odesa',
  'odino',
  'ofise',
  'ofiso',
  'ofisą',
  'ohajo',
  'ohoho',
  'oksis',
  'olego',
  'oloje',
  'olose',
  'omara',
  'omaro',
  'omarą',
  'omarų',
  'omega',
  'omenį',
  'opera',
  'operą',
  'ordos',
  'oriai',
  'orkai',
  'orkas',
  'orumo',
  'orumą',
  'osama',
  'osten',
  'ostin',
  'otomo',
  'oukai',
  'oviau',
  'ozava',
  'ožiai',
  'ožkos',
  'pablo',
  'pabėk',
  'pacia',
  'paciu',
  'pades',
  'padre',
  'padus',
  'paeik',
  'paeme',
  'paimi',
  'paimu',
  'paini',
  'painu',
  'pajus',
  'pakas',
  'pakol',
  'palau',
  'palto',
  'paltu',
  'palūš',
  'pamir',
  'panas',
  'panda',
  'panom',
  'paozo',
  'papra',
  'paras',
  'parei',
  'paris',
  'parku',
  'parkų',
  'paros',
  'paruo',
  'paryt',
  'parše',
  'pasai',
  'pasir',
  'pasta',
  'pasus',
  'patai',
  'patas',
  'paula',
  'paulo',
  'pauzė',
  'pauzę',
  'pavok',
  'pašte',
  'pedro',
  'peles',
  'pelių',
  'pelkė',
  'pelkę',
  'pelna',
  'pelnė',
  'pelės',
  'penas',
  'peniu',
  'penių',
  'penja',
  'peper',
  'pepsi',
  'peris',
  'perki',
  'perlo',
  'perlą',
  'perlų',
  'perry',
  'persų',
  'petia',
  'petra',
  'petro',
  'petys',
  'picas',
  'pienu',
  'pietu',
  'pietò',
  'pieši',
  'piešk',
  'piešt',
  'piešė',
  'pigių',
  'pigūs',
  'piktą',
  'piktų',
  'pildo',
  'pilka',
  'pilki',
  'pilku',
  'pilką',
  'pilno',
  'pilnu',
  'pilti',
  'pilve',
  'pilvu',
  'pimpi',
  'pimpį',
  'pingą',
  'pinki',
  'pintą',
  'pipai',
  'pirks',
  'pirmų',
  'pirty',
  'pitas',
  'piurė',
  'pjauk',
  'pjaut',
  'pjesė',
  'pjesę',
  'pjūvį',
  'place',
  'plakė',
  'plana',
  'plane',
  'planu',
  'plati',
  'platų',
  'plaza',
  'pledą',
  'plepa',
  'plepu',
  'plika',
  'pliki',
  'pliką',
  'ploja',
  'plojo',
  'plona',
  'plote',
  'ploto',
  'plotą',
  'plotų',
  'plyta',
  'plytą',
  'plytų',
  'plyšo',
  'plyšį',
  'plėšė',
  'poema',
  'poemą',
  'point',
  'pokšt',
  'polia',
  'polis',
  'polui',
  'ponio',
  'ponis',
  'ponių',
  'popas',
  'poras',
  'poršė',
  'posto',
  'pozos',
  'prada',
  'pradu',
  'praha',
  'prahą',
  'prane',
  'prekę',
  'press',
  'presą',
  'price',
  'prieù',
  'prima',
  'prime',
  'primo',
  'pripa',
  'prisi',
  'prizo',
  'prizų',
  'prleš',
  'prof.',
  'progų',
  'protų',
  'pudra',
  'pulko',
  'pulką',
  'pulsi',
  'pulso',
  'pulsą',
  'pulto',
  'pultą',
  'pultų',
  'puode',
  'puodo',
  'puoli',
  'puota',
  'puotą',
  'pupas',
  'pupos',
  'puppy',
  'purto',
  'purve',
  'purvu',
  'purvą',
  'pusėn',
  'putos',
  'pykau',
  'pykšt',
  'pypkę',
  'pypsi',
  'pėsti',
  'pūgos',
  'pūslė',
  'pūslę',
  'pūsti',
  'quake',
  'queen',
  'radio',
  'radęs',
  'rafai',
  'rafas',
  'ragai',
  'ragas',
  'ragus',
  'raide',
  'raidė',
  'raidę',
  'raile',
  'railė',
  'raisa',
  'rajan',
  'raktu',
  'ralej',
  'ralfi',
  'ralfą',
  'ralio',
  'ramia',
  'ramią',
  'rampa',
  'ramzi',
  'randi',
  'rando',
  'randą',
  'randų',
  'rangą',
  'ranča',
  'rančą',
  'rasai',
  'rasas',
  'rasit',
  'rasto',
  'rasės',
  'ratui',
  'rauda',
  'rauli',
  'rauze',
  'rayau',
  'rašei',
  'rašma',
  'rašom',
  'rašto',
  'raštu',
  'raštą',
  'rašys',
  'rašėm',
  'rašęs',
  'ready',
  'reali',
  'realu',
  'realų',
  'redži',
  'regiu',
  'regos',
  'reidą',
  'reika',
  'reiko',
  'reikš',
  'reind',
  'reiso',
  'reisu',
  'reisą',
  'rekso',
  'rembo',
  'remia',
  'remis',
  'remti',
  'renai',
  'rengi',
  'rengt',
  'rengė',
  'renki',
  'renku',
  'repas',
  'resas',
  'retos',
  'retro',
  'rezga',
  'rezgi',
  'ricky',
  'riebi',
  'riebų',
  'rieda',
  'riešo',
  'riešą',
  'rikio',
  'rikiu',
  'rikui',
  'rimas',
  'rimtu',
  'ringo',
  'ringą',
  'rinko',
  'rinkt',
  'rinką',
  'ritmo',
  'ritmu',
  'ritos',
  'river',
  'rišti',
  'robin',
  'robio',
  'robui',
  'rodas',
  'rodau',
  'rodei',
  'rodeo',
  'rodin',
  'rodni',
  'roger',
  'roges',
  'rogių',
  'rogės',
  'rojau',
  'rojuj',
  'rokai',
  'rokas',
  'rokio',
  'rokis',
  'rokse',
  'roldi',
  'rolls',
  'roman',
  'romas',
  'ronda',
  'ronis',
  'ronni',
  'roris',
  'rosai',
  'rosas',
  'rotai',
  'rotas',
  'rouzė',
  'rozos',
  'rožes',
  'rudas',
  'rudis',
  'rudos',
  'ruduo',
  'rufai',
  'rufio',
  'ruoši',
  'ruošk',
  'ruošt',
  'ruošė',
  'rusty',
  'rusus',
  'rutai',
  'rutas',
  'rydas',
  'ryklį',
  'rysai',
  'rysas',
  'rytai',
  'rytui',
  'ryšis',
  'ryšiu',
  'ryški',
  'ryžto',
  'ryžtą',
  'rėjui',
  'rėjum',
  'rėkei',
  'rėkiu',
  'rėksi',
  'rėmus',
  'rūbai',
  'rūkas',
  'rūkau',
  'rūkei',
  'rūkyk',
  'rūpiu',
  'rūpės',
  'rūsio',
  'rūsys',
  'rūtas',
  'rūšys',
  'sachs',
  'sagas',
  'sagos',
  'saidą',
  'saint',
  'saire',
  'sakom',
  'saksą',
  'sakėm',
  'sakės',
  'salai',
  'salam',
  'salas',
  'saldi',
  'saldu',
  'sales',
  'salia',
  'salim',
  'salis',
  'saloj',
  'salsa',
  'samai',
  'samas',
  'samdo',
  'sanią',
  'sansa',
  'sapnu',
  'sarah',
  'sarai',
  'sargo',
  'sargu',
  'sargą',
  'sargų',
  'satan',
  'saudo',
  'saugų',
  'saują',
  'sauna',
  'saunu',
  'sauną',
  'sausa',
  'sausi',
  'sauso',
  'sausį',
  'sausų',
  'savam',
  'saves',
  'savom',
  'savos',
  'savus',
  'sašką',
  'scenų',
  'seifų',
  'seima',
  'sekam',
  'sekei',
  'sekme',
  'sekos',
  'sekse',
  'seksi',
  'sektų',
  'sekės',
  'selfą',
  'semui',
  'sende',
  'sendė',
  'seniu',
  'senių',
  'senka',
  'senti',
  'septa',
  'serbų',
  'serli',
  'sersi',
  'sesės',
  'setas',
  'sever',
  'seńor',
  'sfera',
  'shino',
  'siame',
  'siamo',
  'sidas',
  'sidne',
  'sidni',
  'sidnė',
  'siejo',
  'sieks',
  'siekė',
  'siera',
  'silai',
  'silas',
  'silia',
  'silva',
  'simba',
  'simon',
  'sinas',
  'sindė',
  'sioje',
  'sisse',
  'sitai',
  'sitas',
  'sitka',
  'sitos',
  'siuos',
  'siuva',
  'siuvo',
  'siuze',
  'siuzi',
  'siuzė',
  'sičio',
  'siūlą',
  'siūlų',
  'siūti',
  'siųst',
  'skiri',
  'skirk',
  'skirs',
  'skirt',
  'skote',
  'skoto',
  'skotu',
  'skotą',
  'skotė',
  'skotį',
  'skubų',
  'skydu',
  'skydų',
  'skyle',
  'skype',
  'skėtį',
  'slavų',
  'slidi',
  'slidu',
  'sloga',
  'slyvų',
  'slėgį',
  'slėnį',
  'slėpt',
  'slėpė',
  'smagi',
  'smeik',
  'smith',
  'smito',
  'smogs',
  'smuko',
  'smėlį',
  'smūgi',
  'snape',
  'snapą',
  'snoop',
  'snuki',
  'sodai',
  'sodos',
  'sofia',
  'sojat',
  'sojos',
  'solai',
  'sonas',
  'songų',
  'sonia',
  'sonis',
  'sonja',
  'sonmi',
  'sonny',
  'sonos',
  'sorry',
  'soste',
  'sostų',
  'sotus',
  'sotūs',
  'sound',
  'south',
  'soyuz',
  'spago',
  'spalį',
  'speju',
  'spike',
  'spruk',
  'spręs',
  'spurs',
  'spyrė',
  'spyrį',
  'spėja',
  'spėjo',
  'spėsi',
  'srity',
  'sritį',
  'srove',
  'stale',
  'stall',
  'stalų',
  'stand',
  'state',
  'steel',
  'stela',
  'steve',
  'still',
  'stiva',
  'stogu',
  'stogų',
  'stoja',
  'stoji',
  'stojo',
  'stone',
  'stori',
  'storm',
  'storo',
  'storu',
  'stosi',
  'stoty',
  'stoun',
  'stovą',
  'stumi',
  'styvi',
  'styvo',
  'styvu',
  'styvą',
  'suauk',
  'sucre',
  'sudas',
  'suduš',
  'sudėk',
  'sueis',
  'suges',
  'sugis',
  'sugri',
  'suima',
  'suimk',
  'suimt',
  'suimu',
  'sukam',
  'sukit',
  'sukre',
  'sukrė',
  'suksi',
  'sukta',
  'suktą',
  'sulūš',
  'sumai',
  'sumas',
  'sumuš',
  'sunau',
  'sunus',
  'suole',
  'suolo',
  'supus',
  'suris',
  'susan',
  'susib',
  'suski',
  'suskį',
  'susna',
  'suėda',
  'suėdė',
  'suėjo',
  'sušių',
  'svajų',
  'svaro',
  'svaru',
  'svarą',
  'sveik',
  'sveri',
  'svėrė',
  'sweet',
  'sydas',
  'sykio',
  'sykių',
  'synas',
  'sėdam',
  'sėdau',
  'sėdės',
  'sėklą',
  'sėklų',
  'sėkme',
  'sėsim',
  'sėsiu',
  'sūrių',
  'sūrus',
  't-dog',
  'ta-da',
  'tadai',
  'tagai',
  'taiki',
  'taiso',
  'tajau',
  'takai',
  'taksą',
  'talpa',
  'tampo',
  'tampu',
  'tamsų',
  'tanas',
  'tango',
  'tanka',
  'tanko',
  'tanku',
  'tanką',
  'tankų',
  'tanyu',
  'tapom',
  'tapot',
  'taria',
  'tariu',
  'tarno',
  'tarną',
  'tarnų',
  'taros',
  'tarpą',
  'tasai',
  'tatia',
  'tatsi',
  'taukų',
  'taupo',
  'taure',
  'tavņs',
  'tavźs',
  'taške',
  'tašku',
  'tebus',
  'tecio',
  'tedas',
  'teigi',
  'teigė',
  'teiks',
  'teise',
  'tejau',
  'tekti',
  'tekėk',
  'tekės',
  'tekėt',
  'telma',
  'tempi',
  'templ',
  'tempo',
  'tempt',
  'tempu',
  'tempą',
  'tempė',
  'tepai',
  'tepas',
  'terio',
  'teris',
  'tesla',
  'tesos',
  'testi',
  'testu',
  'testų',
  'tetai',
  'tevai',
  'tevui',
  'tešla',
  'thank',
  'there',
  'these',
  'thing',
  'think',
  'thule',
  'tidis',
  'tiedu',
  'tiesi',
  'tiesu',
  'tigai',
  'tigro',
  'tigru',
  'tigrą',
  'tigrų',
  'tikas',
  'tikim',
  'tikit',
  'tikti',
  'tikus',
  'tilpo',
  'tilps',
  'tiltu',
  'tiltų',
  'timis',
  'timui',
  'tingi',
  'tinki',
  'tinku',
  'tioma',
  'tipui',
  'tipus',
  'tiria',
  'tiriu',
  'tirti',
  'tirza',
  'titai',
  'titas',
  'tobio',
  'tobis',
  'todei',
  'tolia',
  'tomio',
  'tomiu',
  'tommy',
  'tonio',
  'toniu',
  'tonos',
  'torai',
  'toras',
  'tortu',
  'tortų',
  'torui',
  'tosto',
  'trapi',
  'trapo',
  'trapų',
  'trasa',
  'trasą',
  'treti',
  'triju',
  'triko',
  'trina',
  'trink',
  'trint',
  'trish',
  'triče',
  'troja',
  'troją',
  'trude',
  'trudi',
  'trudė',
  'tryse',
  'trąšų',
  'trūks',
  'tukas',
  'tunas',
  'turas',
  'turbo',
  'tures',
  'turkų',
  'turnė',
  'turte',
  'turtu',
  'turįs',
  'tušas',
  'tvora',
  'tylit',
  'tyliu',
  'tylią',
  'tylėt',
  'tyras',
  'tyros',
  'tünde',
  'tėkmę',
  'tėtės',
  'tėvam',
  'tėvuk',
  'tęsia',
  'tęsim',
  'tęsiu',
  'tūrio',
  'tūzas',
  'udyti',
  'ugnim',
  'uh-oh',
  'uhura',
  'union',
  'uodas',
  'uogas',
  'uogos',
  'uolas',
  'uoslę',
  'uostų',
  'uošvė',
  'upelį',
  'uralo',
  'urano',
  'urvas',
  'urvus',
  'užauk',
  'uždus',
  'uždės',
  'uždėt',
  'užeis',
  'užges',
  'užgis',
  'užimk',
  'užimt',
  'užtai',
  'vaako',
  'vadus',
  'vagie',
  'vagiu',
  'vaika',
  'vaili',
  'vaito',
  'vajos',
  'valai',
  'valau',
  'valda',
  'valei',
  'valgi',
  'valgį',
  'valsą',
  'valyk',
  'valyt',
  'vanai',
  'vanda',
  'vania',
  'vanko',
  'varda',
  'vargą',
  'varia',
  'vario',
  'varis',
  'varlė',
  'varlę',
  'varna',
  'varno',
  'varot',
  'varpe',
  'varpo',
  'varpą',
  'varva',
  'varyt',
  'vasia',
  'vatos',
  'vazos',
  'vaško',
  'vebas',
  'vedei',
  'vedes',
  'vegas',
  'veida',
  'veinu',
  'veiną',
  'veinų',
  'vejas',
  'vejos',
  'velai',
  'velse',
  'velso',
  'vemia',
  'vemti',
  'venas',
  'vende',
  'vendi',
  'vendė',
  'vengi',
  'verai',
  'verdi',
  'verdu',
  'verge',
  'vergo',
  'vergu',
  'vergė',
  'vergł',
  'verks',
  'verkt',
  'veros',
  'versi',
  'versk',
  'verte',
  'vertu',
  'vertą',
  'vestų',
  'vetra',
  'vešim',
  'vežam',
  'vežat',
  'vidui',
  'vidus',
  'viela',
  'vielą',
  'vienų',
  'vieša',
  'vieši',
  'viešo',
  'viešą',
  'viešų',
  'vikas',
  'vikos',
  'vilio',
  'viliu',
  'vilką',
  'vilna',
  'vilos',
  'vilui',
  'vinai',
  'vingį',
  'vinis',
  'vinių',
  'vinys',
  'virpa',
  'virti',
  'virve',
  'virvė',
  'viski',
  'viskį',
  'visoj',
  'vista',
  'vitai',
  'vitas',
  'vizas',
  'vizli',
  'vizos',
  'vištų',
  'vlado',
  'vladu',
  'vladą',
  'vodka',
  'vogtą',
  'vogtų',
  'vogue',
  'voila',
  'vokai',
  'vokas',
  'vokie',
  'vokus',
  'voler',
  'voltų',
  'volvo',
  'vonai',
  'vonas',
  'vorai',
  'vordo',
  'vorui',
  'vorus',
  'vosai',
  'vosas',
  'vudai',
  'vudas',
  'vudis',
  'vykau',
  'vykdo',
  'vykdė',
  'vykis',
  'vykit',
  'vyksi',
  'vykęs',
  'vyram',
  'vyruk',
  'vytis',
  'vyton',
  'vyver',
  'vėjui',
  'vėlgi',
  'vėlus',
  'vėpla',
  'vėrie',
  'vėsus',
  'vėžes',
  'vėžių',
  'vėžly',
  'walsh',
  'wanna',
  'water',
  'where',
  'white',
  'whole',
  'wi-fi',
  'woman',
  'woody',
  'world',
  'would',
  'yasin',
  'yohei',
  'yorko',
  'zakas',
  'zedai',
  'zelda',
  'zemes',
  'zerga',
  'zmona',
  'zodis',
  'zonoj',
  'zorba',
  'zuike',
  'zuiki',
  'zuikį',
  'zukas',
  'ùiaur',
  'čadas',
  'čakas',
  'čakis',
  'čanas',
  'čapis',
  'čarle',
  'čarlė',
  'čarlį',
  'čedai',
  'čedas',
  'čekio',
  'čekiu',
  'čelsi',
  'čenai',
  'čerli',
  'černo',
  'četai',
  'čipai',
  'čipas',
  'čonai',
  'čonas',
  'čurda',
  'ėjimą',
  'ėjimų',
  'ėjome',
  'ėjosi',
  'ėmėme',
  'ėmėte',
  'įdavė',
  'įdeda',
  'įdedi',
  'įdomų',
  'įdėja',
  'įdėsi',
  'įdėta',
  'įdūrė',
  'įeini',
  'įeinu',
  'įeisi',
  'įgaus',
  'įgavo',
  'įgijo',
  'įgysi',
  'įgyti',
  'įgėlė',
  'įjuos',
  'įjūsų',
  'įkalė',
  'įkiša',
  'įkiši',
  'įkišk',
  'įkišt',
  'įkrės',
  'įkyri',
  'įkėlė',
  'įleis',
  'įlipa',
  'įlipo',
  'įlįst',
  'įmesk',
  'įmest',
  'įmeta',
  'įmetė',
  'įmonę',
  'įpilt',
  'įraše',
  'įrašė',
  'įrodė',
  'įsako',
  'įspėk',
  'įspėt',
  'įsėdo',
  'įtaka',
  'įtari',
  'įtars',
  'įvarė',
  'įvedė',
  'įvesk',
  'įvykt',
  'įvykę',
  'įvėlė',
  'įėjai',
  'įėjom',
  'įėjęs',
  'įžūli',
  'įžūlu',
  'šagal',
  'šakas',
  'šaknų',
  'šakos',
  'šalmo',
  'šalmu',
  'šalmų',
  'šalti',
  'šaltu',
  'šaltą',
  'šaltį',
  'šaltų',
  'šalys',
  'šanel',
  'šansu',
  'šarla',
  'šarvų',
  'šasta',
  'šauki',
  'šaukt',
  'šaulį',
  'šaunų',
  'šausi',
  'šauta',
  'šefai',
  'šefui',
  'šefės',
  'šeila',
  'šelai',
  'šelas',
  'šemai',
  'šenon',
  'šerdį',
  'šeril',
  'šerne',
  'šerną',
  'šerti',
  'šešta',
  'šešto',
  'šiedu',
  'šieno',
  'šieną',
  'šifrą',
  'šiknė',
  'šikti',
  'šildo',
  'šilko',
  'šilti',
  'šilto',
  'šiltą',
  'šiltų',
  'šimtu',
  'šioji',
  'šioks',
  'šiokį',
  'šioms',
  'širdi',
  'širdy',
  'širle',
  'širšė',
  'šitom',
  'šitus',
  'škotų',
  'šlapi',
  'šmiki',
  'šneku',
  'šnipo',
  'šnipu',
  'šnipą',
  'šnipė',
  'šnipų',
  'šokai',
  'šokam',
  'šokau',
  'šokiu',
  'šoksi',
  'šoktų',
  'šokęs',
  'šonui',
  'šonus',
  'šotus',
  'šovei',
  'špaga',
  'štabe',
  'štabo',
  'štabą',
  'šudas',
  'šukas',
  'šunim',
  'šuolį',
  'šutve',
  'šutvė',
  'švara',
  'švarą',
  'švarų',
  'švedą',
  'švedų',
  'švies',
  'švino',
  'šviną',
  'švyst',
  'švyti',
  'švęst',
  'šüdas',
  'šėlti',
  'šįsyk',
  'šūdai',
  'šūdui',
  'šūdus',
  'šūkis',
  'šūvio',
  'šūviu',
  'šūvių',
  'ūkyje',
  'ūsais',
  'ūydai',
  'ųemós',
  'žadat',
  'žaibe',
  'žaibo',
  'žaibą',
  'žaibų',
  'žaisi',
  'žaisk',
  'žaliu',
  'žanai',
  'žanas',
  'žandą',
  'žarna',
  'žarną',
  'žarnų',
  'žavūs',
  'žemei',
  'žemos',
  'žemėn',
  'žengi',
  'žengs',
  'žengt',
  'žengė',
  'ženia',
  'žibie',
  'žiede',
  'žiedu',
  'žinąs',
  'žirgu',
  'žmogu',
  'žmonų',
  'žolių',
  'žuber',
  'žudau',
  'žudei',
  'žudyk',
  'žudys',
  'žudyt',
  'žuvai',
  'žuvau',
  'žuvie',
  'žuvim',
  'žuvęs',
  'žvakė',
  'žvakę',
  'žvejų',
  'žvelk',
  'žydra',
  'žydus',
  'žydėk',
  'žygio',
  'žygis',
  'žygių',
  'žymes',
  'žymos',
  'žymus',
  'žymūs',
  'žynys',
  'žąsys',
  'žūsim',
  'žūsiu',
  'žūtis',
]
